<template>
  <select required v-model="result">
    <option value="" selected disabled hidden>{{ label }}</option>
    <option v-for="(o, i) of options" :value="o.value" :key="i">
      {{ o.label }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    result: {
      get() {
        return this.modelValue || "";
      },
      set(val) {
        if (!val || val === "") return;
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>

<style></style>
